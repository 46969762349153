import theme from "@giraldomac/gatsby-theme-mmdbase/src/gatsby-plugin-theme-ui"

export default {
    ...theme,
    initialColorMode: 'light',
    useCustomProperties: true,
    colors: {
        ...theme.colors,
        primary: '#0095d7',
        background: theme.colors.gray[2],
        wrapperbox: theme.colors.white,
        link: theme.colors.blue[5],
        linkHover: theme.colors.blue[6],
        modes: {
            dark: {
                background: theme.colors.black,
                text: theme.colors.gray[2],
                wrapperbox: theme.colors.gray[8],
            },
            light: {
                text: theme.colors.gray[8],
            }
        }
    },
    fonts: {
        body: `"Source Sans Pro", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
        heading: `"Source Sans Pro", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
    },
    layout: {
        root: {

        },
        header: {
            background: theme.colors.white,
        },
        footer: {
            text: theme.colors.gray[3],
            background: theme.colors.gray[8],
            textAlign: 'center',
            p: {
                fontSize: [0],
                marginBottom: 0,
            }
        }
    },
    styles: {
        ...theme.styles,
        p: {
            fontFamily: 'body',
            lineHeight: `body`,
            "--baseline-multiplier": 0.179,
            "--x-height-multiplier": 0.35,
            m: 0,
        },
        h1: {
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            m: 0,
            mb: 1,
            fontSize: [5, 6],
            mt: 2
        },
        h2: {
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            m: 0,
            mb: 1,
            fontSize: [3, 4],
            mt: 2
        },
        h3: {
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            m: 0,
            mb: 1,
            fontSize: [2, 3],
            mt: 3
        },
        h4: {
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            m: 0,
            mb: 1,
            fontSize: [1, 2]
        },

    }
}
