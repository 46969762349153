module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"parkersburg-utility-board","short_name":"pub","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"pubwv","defaultLang":"en-us","omitPrismicScript":true,"previews":true,"pages":[{"type":"Project","match":"/projects/:uid","path":"/projects-prev","component":"/opt/build/repo/src/templates/project.js"},{"type":"General_notices","match":"/notices/general/:uid","path":"/notices-prev","component":"/opt/build/repo/src/templates/notice.js"},{"type":"Emergency_notices","match":"/notices/emergencies/:uid","path":"/notices-prev","component":"/opt/build/repo/src/templates/notice_emergency.js"},{"type":"Interruptions_notices","match":"/notices/interruptions/:uid","path":"/notices-prev","component":"/opt/build/repo/src/templates/notice_interruption.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-68801010-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
